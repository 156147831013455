<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增会员等级</el-button>
			</el-row>
			<!-- 会员等级表单 -->
			<el-table :data="levelData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="grade_name" label="会员等级"></el-table-column>
				<el-table-column prop="grade_money" label="累计充值金额(元)"></el-table-column>
				<el-table-column prop="grade_zkbl" label="消费折扣比例(%)"></el-table-column>
				<el-table-column prop="grade_sort" label="等级排序"></el-table-column>
				<el-table-column prop="add_time" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="36%" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="150px" :rules="addlevelRules">
					<el-form-item label="会员等级" prop="grade_name">
						<el-input v-model="addlevelForm.grade_name" maxlength="10" placeholder="请输入会员等级名称"></el-input>
					</el-form-item>
					<el-form-item label="累计充值金额(元)" prop="grade_money">
						<el-input v-model.number="addlevelForm.grade_money" placeholder="请输入累计充值金额"></el-input>
					</el-form-item>
					<el-form-item label="消费折扣比例(%)" prop="grade_zkbl">
						<el-input v-model.number="addlevelForm.grade_zkbl" placeholder="请输入消费折扣比例"></el-input>
					</el-form-item>
					<el-form-item label="等级排序" prop="grade_sort">
						<el-input v-model.number="addlevelForm.grade_sort" placeholder="请输入排序序号"></el-input>
					</el-form-item>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增会员等级",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					grade_name: '',
					grade_money: '',
					grade_zkbl: '',
					grade_sort: 99
				},
				addlevelRules: {
					grade_name: [{
						required: true,
						message: "请输入会员等级名称",
						trigger: "blur"
					}, ],
					grade_money: [{
							required: true,
							message: "请输入累计充值金额",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					grade_zkbl: [{
							required: true,
							message: "请输入消费折扣比例",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					],
					grade_sort: [{
							required: true,
							message: "请输入排序序号",
							trigger: "blur"
						},
						{
							type: "number",
							message: "请输入数字"
						},
					]
				},
				levelData: []
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getUserGrade();
			}
		},
		methods: {
			getUserGrade() {
				var url = 'user_grade/grade_list';
				let params = {
					grade_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增会员等级
			addLevel() {
				this.title = "新增会员等级";
				this.addlevelForm = {
					grade_name: '',
					grade_money: '',
					grade_zkbl: '',
					grade_sort: 99
				};
				this.addLevelDialog = true;
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'user_grade/addOrUpdate';
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getUserGrade();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},1000);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑会员等级";
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
				this.addLevelDialog = true;
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'user_grade/deleteGrade';
					let params = {
						grade_id: item.grade_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getUserGrade();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getUserGrade();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getUserGrade();
			},
		},
	}
</script>

<style lang="scss" scoped>
	.el-row{
	  text-align: left;
	  margin-bottom: 20px;
	}
</style>